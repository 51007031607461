/* Header.module.css */

.headerBackground {
  background-image: url('../assets/img/background/background5.jpg');
  background-size: 100%;
  background-position: 'center';
  position: relative;
  }


  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* Ajuste o alpha para a intensidade da sombra */
    z-index: 1; /* Garante que o overlay fique acima da imagem */
  }

  .headerContent {
    position: relative;
    z-index: 2; /* Garante que o conteúdo fique acima do overlay */
  }

  .headerOverlay {
    z-index: 2; /* Garante que o conteúdo fique acima do overlay */
  }