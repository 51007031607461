.info-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  max-width: 350px;
  height: 450px; /* Altura fixa */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.info-card-img {
  border-radius: 8px 8px 0 0;
  height: 200px; /* Altura fixa para a imagem */
  object-fit: cover; /* Ajusta a imagem para preencher o espaço */
}

.info-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Garante que o meta info fique no rodapé */
  height: 100%;
  padding: 0.5rem; /* Padding reduzido */
}

.info-card-category {
  color: #3B93D3;
  font-weight: bold;
  margin-bottom: 0.25rem; /* Margem reduzida */
}

.info-card-title {
  font-size: 1.25rem; /* Tamanho de fonte reduzido */
  font-weight: bold;
  margin: 0.25rem 0; /* Margem reduzida */
}

.info-card-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Ajuste o número de linhas conforme necessário */
  text-overflow: ellipsis;
  margin-bottom: 0.25rem; /* Margem reduzida */
}

.info-card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c757d;
  margin-top: auto; /* Garante que a seção fique no fundo */
  padding-top: 0.5rem; /* Espaçamento entre o texto e o meta */
}
